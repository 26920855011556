import React from 'react';
import Loader from 'react-loader-spinner';

function Loading() {
  return (
    <div className='loadingdots go-top'>
      <Loader
        type='MutatingDots'
        color='#fad614'
        secondaryColor='#202452'
        height={100}
        width={100}
        timeout={15000000}
      />
      <h3>Loading...</h3>
    </div>
  );
}

export default Loading;
