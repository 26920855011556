import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

function Banner() {
  const history = useHistory();
  const [keyword, setKeyword] = useState('');

  let publicUrl = process.env.PUBLIC_URL + '/';

  let searchModules = async (event) => {
    event.preventDefault();
    if (!keyword || keyword === '') return;
    history.push('/modules?keyword=' + encodeURIComponent(keyword));
  };

  return (
    <div
      data-testid='banner'
      className='banner-area'
      style={{ background: 'url(' + publicUrl + 'assets/img/header-home.png)' }}
    >
      <div className='container'>
        <div className='banner-area-inner'>
          <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <div className='banner-inner text-center'>
                <h2>Etendo Marketplace</h2>
                <p>Install the modules that you need for your business. </p>
              </div>
            </div>
            <div className='col-md-10'>
              <form data-testid='search-bar-homepage' onSubmit={searchModules}>
                <input
                data-testid='input-homepage'
                  type='text'
                  className='form-control'
                  value={keyword}
                  onChange={(evt) => setKeyword(evt.target.value)}
                />
                <button
                  data-testid='submit-search-homepage'
                  type='submit'
                  className='btn'
                >
                  <img
                    src={publicUrl + 'assets/img/icons/icon-search.png'}
                    alt='Search button icon'
                  />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
