import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp, FaLinkedinIn } from 'react-icons/fa';
import './Footer.scss';

class Footer_v1 extends Component {
  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    const minscript = document.createElement('script');
    minscript.async = true;
    minscript.src = publicUrl + 'assets/js/main.js';

    document.body.appendChild(minscript);
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';

    return (
      <footer className='footer-area pd-top-100 '>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-2 col-md-6'>
              <div className='footer-widget widget widget_nav_menu'>
                <div className='thumb mt-0'>
                  <img
                    src={publicUrl + 'assets/img/logo-etendo-2.png'}
                    alt='Etendo logo'
                  />
                </div>
                <ul className='social-area'>
                  <li>
                    <a href='https://api.whatsapp.com/send?phone=+5493584162489&text=Hello!!'>
                      <FaWhatsapp />
                    </a>
                  </li>
                  <li>
                    <a href='https://www.linkedin.com/company/etendo/about/'>
                      <FaLinkedinIn />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='footer-widget widget widget_contact emkpFooter-info-contact'>
                <h4 className='widget-title emkpFooter-info-contact-title'>Contact Us</h4>
                <div className='media emkpFooter-info-contactLocation-media'>
                  <div className='thumb emkpFooter-info-contactLocation-mediaThumb'>
                    <img
                      src={publicUrl + 'assets/img/icons/icon-location.png'}
                      className='icon-width'
                      alt='Icon location'
                    />
                  </div>
                  <div className='media-body emkpFooter-info-contactLocation-mediaBody'>
                    <a
                      href='https://www.google.com/maps/place/Eustasio+Amilibia+Kalea,+10,+20011+Donostia,+Gipuzkoa,+Spain/@43.3052458,-1.980185,17z/data=!3m1!4b1!4m5!3m4!1s0xd51aff087406921:0x70c6fcd1b2b23733!8m2!3d43.3052458!4d-1.9779963'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Eustasio Amilibia 10
                      Donostia, Gipuzkoa, Spain
                    </a>
                  </div>
                </div>
                <div className='media emkpFooter-info-contactEmail-media'>
                  <div className='thumb mt-0 emkpFooter-info-contactEmail-mediaThumb'>
                    <img
                      src={publicUrl + 'assets/img/icons/icon-email.png'}
                      alt='img'
                    />
                  </div>
                  <div className='media-body emkpFooter-info-contactEmail-mediaBody'>
                    <p className='m-0'>
                      <a href='mailto:info@etendo.software'>
                        info@etendo.software
                      </a>
                    </p>
                  </div>
                </div>
                <div className='media emkpFooter-info-contactTel-media'>
                  <div className='thumb mt-0 emkpFooter-info-contactTel-mediaThumb'>
                    <img
                      src={publicUrl + 'assets/img/icons/icon-telephone.png'}
                      alt='img'
                    />
                  </div>
                  <div className='media-body emkpFooter-info-contactTel-mediaBody'>
                    <p className='m-0'>
                      <a href='tel:+34647741562'>
                        +34 647741562
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-6'>
              <div className='footer-widget widget widget_nav_menu emkpFooter-info-siteMap'>
                <h4 className='widget-title emkpFooter-info-siteMap-title'>Site Map</h4>
                <ul className='emkpFooter-info-siteMap-listItems'>
                  <li className='emkpFooter-info-siteMap-listItems-element'>
                    <Link to='/' className='go-top'>
                      <p>Home</p>
                    </Link>
                  </li>
                  <li className='emkpFooter-info-siteMap-listItems-element'>
                    <Link to='/modules' className='go-top'>
                      <p>Modules</p>
                    </Link>
                  </li>
                  <li className='emkpFooter-info-siteMap-listItems-element'>
                    <a
                      href='https://etendo.software/get-in-touch/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <p>Contact</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='footer-widget widget widget_nav_menu emkpFooter-info-support'>
                <h4 className='widget-title'>Support</h4>
                <ul>
                  <li>
                   We are here to help you!
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='copyright-area'>
            <div className='row'>
              <div className='col-lg-6 align-self-center'>
                <p>© 2021 Etendo. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer_v1;
