import React from 'react';
import Navbar from '../Navbar/Navbar';
import Banner from '../Banner/Banner';
import Featured from '../Featured/Featured';
import Footer from '../Footer/Footer';

const Home_V1 = () => {
  return (
    <div data-testid='home'>
      <Navbar />
      <Banner />
      <Featured />
      <Footer />
    </div>
  );
};

export default Home_V1;
