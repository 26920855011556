import { OBRest, Restrictions } from 'obrest';
import axios from 'axios';

const baseURL = 'https://marketplace.etendo.cloud/etendo';
const elasticURL = '/sws/com.etendoerp.integration.elasticsearch.obElastic/';
const elasticModuleFields =
  'title,overview,partner,tags,javapackage,javapackagestrict,category';

export default class ETApi {
  static async initApi() {
    OBRest.init({ href: baseURL });
  }

  static async getCategoriesData() {
    let criteria = await OBRest.getInstance()
      .createCriteria('emkp_category')
      .addOrderBy('pageOrder', true)
      .setShowIdentifiers(true);
    let results = await criteria.list();
    return results;
  }

  static async getQualitiesData() {
    let criteria = await OBRest.getInstance()
      .createCriteria('emkp_quality')
      .addOrderBy('importance', true)
      .setShowIdentifiers(true);
    let results = await criteria.list();
    return results;
  }

  static async getPartnersData() {
    let criteria = OBRest.getInstance()
      .createCriteria('emkp_partner')
      .setShowIdentifiers(true);
    let results = await criteria.list();
    return results;
  }

  static async getCategories() {
    this.categories = await this.getCategoriesData();
    return this.categories;
  }

  static async getCategory(id) {
    this.categories = await this.getCategoriesData();
    return this.categories.find((category) => category.id === id);
  }

  static getQualities() {
    return this.qualities;
  }

  static async getQuality(id) {
    this.qualities = await this.getQualitiesData();
    return this.qualities.find((quality) => quality.id === id);
  }

  static async getPartners() {
    this.partners = await this.getPartnersData()
    return this.partners;
  }

  static async getPartner(id) {
    this.partners = await this.getPartnersData();
    return this.partners.find((partner) => partner.id === id);
  }

  static async getAllModules() {
    let criteria = await OBRest.getInstance()
      .createCriteria('emkp_module')
      .setShowIdentifiers(true);
    let results = await criteria.list();
    return results;
  }

  static async getFeaturedModules() {
    this.qualities = await this.getQualitiesData();
    let featuredQualities = this.qualities.filter(
      (quality) => quality.importance > 70
    );
    if (!featuredQualities || !featuredQualities.length) return [];
    let criteriaRestrictions = [];

    featuredQualities.forEach((quality) => {
      criteriaRestrictions.push(Restrictions.equals('quality', quality.id));
    });

    let criteria = await OBRest.getInstance()
      .createCriteria('emkp_module')
      .setShowIdentifiers(true)
      .add(Restrictions.or(criteriaRestrictions));

    let result = await criteria.list();
    return result ? result : [];
  }

  static async getModule(id) {
    if(id){
      let criteria = await OBRest.getInstance()
      .createCriteria('emkp_module')
      .setShowIdentifiers(true)
      .add(Restrictions.equals('id', id));
    let result = await criteria.uniqueResult();
    if(result !== undefined && result !== null){
      let mediaCriteria = await OBRest.getInstance()
      .createCriteria('emkp_module_media')
      .setShowIdentifiers(true)
      .add(Restrictions.equals('module', result.id));
    let mediaResult = await mediaCriteria.list();

    let categoryCriteria = await OBRest.getInstance()
      .createCriteria('emkp_module_category')
      .setShowIdentifiers(true)
      .add(Restrictions.equals('module', result.id));
    let categoryResult = await categoryCriteria.uniqueResult();
    return { result, mediaResult, categoryResult };
    }else{
      return null
    }
    }else{
      return null
    }
  }

  static async getMediaData() {
    let criteria = await OBRest.getInstance()
      .createCriteria('emkp_module_media')
      .setShowIdentifiers(true);
    let result = await criteria.list();
    return result;
  }

  static async searchModules(
    keyword,
    pageOffset = null,
    pageSize = null,
    filter = null,
  ) {
    let requestParams = {
      _fields: elasticModuleFields,
      _search: keyword,
    };

    if (pageOffset) requestParams._startRow = pageOffset;
    if (pageSize) requestParams.maxResults = pageSize;
    if (filter && filter !== '') requestParams._filter = filter;

    let modulesData = null;
    let modulesCount = 0;

    await axios
      .get(baseURL + elasticURL + 'emkp_module', {
        params: requestParams,
      })
      .then(function (response) {
        if (
          response.data.data &&
          response.data.data.length &&
          response.data.data[0].data.length
        ) {
          modulesData = response.data.data[0].data;
          modulesCount = response.data.data[0].total;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return { modulesData, modulesCount };
  }

  static async searchModulesByCategory(
    category,
    pageOffset = null,
    pageSize = null
  ) {
    let results = [];

    let criteria = await OBRest.getInstance()
      .createCriteria('emkp_module_category')
      .setShowIdentifiers(true)
      .add(Restrictions.equals('category', category));

    let criteriaResult = await criteria.list();

    if (criteriaResult.length) {
      let modulesFound = criteriaResult.map((criteria) => {
        return criteria.module;
      });

      let modulesCriteria = await OBRest.getInstance()
        .createCriteria('emkp_module')
        .setShowIdentifiers(true)
        .add(Restrictions.inSet('id', modulesFound));
      if (pageOffset) modulesCriteria.setFirstResult(pageOffset);
      if (pageSize) modulesCriteria.setMaxResults(pageSize);

      results = modulesCriteria.list();
    }

    return results ? results : [];
  }

  static async getImage(id) {

  let mediaCriteria = await OBRest.getInstance()
    .createCriteria('emkp_module_media')
    .setShowIdentifiers(true)
    .add( Restrictions.and([
      Restrictions.equals('module', id),
      Restrictions.equals('card','true')
    ]
      ));
    return mediaCriteria.uniqueResult();
  }
}
