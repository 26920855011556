import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ETApi from '../../../ETApi/ETApi';
import ReactMarkdown from 'react-markdown';
import ProductSlider from '../ProductSlider/ProductSlider';
import ModuleCode from '../../ModuleCode/ModuleCode';
import { IoIosArrowRoundBack } from 'react-icons/io';
import Loading from '../../Loading/Loading';
import Error from '../../Error/Error';
import './ProductDetails.scss'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const state = { previousComponent: '/', search: '' };

function ProductDetails() {
  const query = useQuery();
  const history = useHistory();
  const [dataModule, setDataModule] = useState({});
  const [mediaModule, setMediaModule] = useState([]);
  const [partner, setPartner] = useState({});
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const [previousComponent] = useState(
    location.state !== undefined
      ? location.state.previousComponent
      : state.previousComponent
  );
  const [search] = useState(
    location.state !== undefined ? location.state.search : state.search
  );
  const [idModule] = useState(query.get('module') ? query.get('module') : '');
  const [loading, setLoading] = useState(true);
  const [dataDescription, setDataDescription] = useState(false);

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initData = async () => {
    window.scrollTo(0, 0);
    await ETApi.initApi();
    let moduleData = await ETApi.getModule(idModule);
    setLoading(dataModule !== {} ? false : true);
    if (moduleData !== null && moduleData.result !== null && moduleData.result.description !== null) {
      setDataDescription(true);
    }

    if (moduleData !== null) {
      let partner = await ETApi.getPartner(moduleData.result.partner);
      setPartner(partner);
      setMediaModule(moduleData.mediaResult);
      setDataModule(moduleData.result);
      if (moduleData.categoryResult) {
        setCategories(moduleData.categoryResult);
      }

      if (moduleData.result.tags !== null) {
        let dataTags = moduleData.result.tags.split(',');
        setTags((tags) => [...tags, dataTags]);
      } else if (moduleData.result.tags === null) {
        return;
      }
    } else {
      setDataModule(moduleData);
    }
  };

  const checkIfIsEmpty = (object) => {
    return object === null || object === undefined || object === '';
  }

  const goBack = () => {
    history.push({
      pathname: previousComponent,
      search: search,
    });
  };

  return (
    <section
      data-testid='product-details'
      className='product-details pd-top-100'
    >
      {!checkIfIsEmpty(idModule) && !checkIfIsEmpty(dataModule) ? (
        <div className='container'>
          <button
            className='badge-pill-card '
            style={{ display: 'flex', alignItems: 'center', fontSize: 15 }}
            onClick={() => goBack()}
            data-testid='goBack-button'
          >
            <IoIosArrowRoundBack size={25} />
            Go Back
          </button>
          {loading === true ? (
            <Loading />
          ) : (
            <div className='row'>
              <div className='col-lg-8'>
                <div className='single-product-wrap emkpProductDetails-modulePresentation'>
                  <div className='single-product-details emkpProductDetails-moduleTitle'>
                    <h3 data-testid='title-section'>{dataModule.title} </h3>
                    {dataModule.overview ? (
                      <p
                        data-testid='overview-section'
                        className='pd-top-10 pd-bottom-30 emkpProductDetails-moduleOverview'
                      >
                        {dataModule.overview}
                      </p>
                    ) : <></>}
                  </div>
                  <div className={'emkpProductDetails-moduleSlider'}>
                    {mediaModule !== [] ? (
                      <ProductSlider
                        className={'emkpProductDetails-image-productSlider'}
                        img={mediaModule}
                      />
                    ) : <></>}
                  </div>
                </div>
                <div data-testid='description-section' className='product-tab emkpProductDetails-moduleReview'>
                  <ul className='nav nav-pills emkpProductDetails-listOptions'>
                    <li className='nav-item emkpProductDetails-option-descriptionButton'>
                      <a
                        className='nav-link active'
                        data-toggle='pill'
                        href='#pills-home'
                      >
                        Description
                      </a>
                    </li>
                    <li className='nav-item emkpProductDetails-option-installButton'>
                      <a
                        className='nav-link'
                        data-toggle='pill'
                        href='#pills-install'
                      >
                        Install
                      </a>
                    </li>
                  </ul>
                  <div className='tab-content'>
                    <div className='tab-pane fade show active emkpProductDetails-option-descriptionListContainer' id='pills-home' data-testid='a'>
                      {dataDescription === true ? (
                        <ReactMarkdown className='emkpProductDetails-option-descriptionList'>{dataModule.description}</ReactMarkdown>
                      ) : (
                        'There is no description available at the moment'
                      )}
                      {dataModule.keyFeatures ? (
                        <>
                          <h3
                            data-testid='key-features-section'
                            className='title'
                          >
                            Key Features
                          </h3>
                          <ReactMarkdown>
                            {dataModule.keyFeatures}
                          </ReactMarkdown>
                        </>
                      ) : <></>}
                    </div>
                    <div className='tab-pane fade ' id='pills-install'>
                      <div className='single-review'>
                        {dataModule.installationGuide ? (
                          <>
                            <h3
                              data-testid='installation-guide-section'
                              className='title'
                            >
                              Installation Guide
                            </h3>
                            <ReactMarkdown>
                              {dataModule.installationGuide}
                            </ReactMarkdown>
                          </>
                        ) : <></>}
                        <figure>
                          <ModuleCode
                            javaPackage={dataModule.javaPackage}
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='sidebar-area'>
                  <div className='widget widget-client'>
                    <div className='partner-section-container' data-testid='partner-section-container'>
                      <div className='partner-section-title-container'>
                        <h4 className='partner-section-title'>
                          Partner
                        </h4>
                      </div>
                      <div className='partner-section-body'>
                        <div className='partner-section-body-image-container'>
                          <Link
                            to={`/modules?partner=${partner.id}`}>
                            <img className='partner-section-body-image' src={partner.image} alt='Partner' />
                          </Link>
                        </div>
                        <div className='partner-section-body-info-container'>
                          <Link
                            to={`/modules?partner=${partner.id}`}>
                            <p className='partner-section-body-info'>{partner.name}</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='widget widget-list'>
                    {tags.length !== 0 ? (
                      <>
                        <h4 data-testid='tags-section' className='title'>
                          Tags
                        </h4>
                        {tags.map((tag) => {
                          return tag.map((t) => {
                            return (
                              <span className='badge badge-pill badge-primary'>
                                <Link
                                  to={`/modules?tags=${t}`}
                                  data-testid='click-tags'
                                >
                                  {t}
                                </Link>
                              </span>
                            );
                          });
                        })}
                      </>
                    ) : <></>}
                    <h4 className='title'>Category</h4>
                    {categories._identifier ? (
                      <p>{categories._identifier}</p>
                    ) : (
                      <></>
                    )}

                    {dataModule.fromCore || dataModule.toCore ? (
                      <>
                        <h4 data-testid='core-section' className='title'>
                          Core Compatibility
                        </h4>
                        <p>From: {dataModule.fromCore}</p>
                        <p>To: {dataModule.core}</p>
                      </>
                    ) : <></>}
                    {dataModule.emkpLicense$_identifier ? (
                      <>
                        <h4 data-testid='license-section' className='title'>
                          License
                        </h4>
                        <p>{dataModule.emkpLicense$_identifier}</p>
                      </>
                    ) : <></>}
                    {dataModule.support ? (
                      <div className='emkpProductDetails-support-container'>
                        <h4 data-testid='support-section' className='title'>
                          Support
                        </h4>
                        <ReactMarkdown>{dataModule.support}</ReactMarkdown>
                      </div>
                    ) : <></>}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='container' data-testid='section-error'>
          <Error data-testid='error' />
        </div>
      )}
    </section>
  );
}

export default ProductDetails;
