/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from '../../Loading/Loading';
import Card from '../../Card/Card';
import ETApi from '../../../ETApi/ETApi'
import { MdClose } from 'react-icons/md';
import './ProductCategory.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ProductCategory() {
  let query = useQuery();
  const [dataModule, setDataModule] = useState([]);
  const [moduleCount, setModuleCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [partners, setPartners] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(
    query.get('category') ? [query.get('category')] : []
  );
  const [selectedPartners, setSelectedPartners] = useState(
    query.get('partner') ? [query.get('partner')] : []
  );
  const [keyword, setKeyword] = useState(
    query.get('keyword') ? query.get('keyword') : ''
  );
  const [pageOffset, setPageOffSet] = useState(0);
  const [pageSize] = useState(6);
  const [pageQuantity, setPageQuantity] = useState(1);
  const [disableCategoryChecks, setDisableCategoryChecks] = useState(
    query.get('category') ? true : false
  );
  const [disablePartnerChecks, setDisablePartnerChecks] = useState(
    query.get('partner') ? true : false
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(query.get('page') ? query.get('page') : 1);
  const [selectedTags, setSelectedTags] = useState(
    query.get('tags') ? [query.get('tags')] : []
  );
  const [showTags, setShowTags] = useState(query.get('tags') ? true : false);
  const [urlSearch, setUrlSearch] = useState('');
  const location = useLocation();

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    searchModules(null, null, page);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectedCategories, selectedPartners, selectedTags]);

  let initData = async () => {
    await ETApi.initApi();
    let categoriesData = await ETApi.getCategories();
    let partnersData = await ETApi.getPartners();
    setCategories(categoriesData);
    setPartners(partnersData);
  };

  let searchModules = async (searchKeyword, event = null, less = null) => {
    if (event) {
      event.preventDefault();
    }
    let newPageOffSet = pageOffset;
    let newPage;
    if (isNaN(less) || less === true || less === false) {
      if (less === true) {
        newPage = page - 1;
      } else {
        newPage = page + 1;
      }
    } else {
      if (event) {
        newPage = Number(event.target.value);
      } else {
        newPage = Number(less);
      }
    }

    newPageOffSet = (newPage - 1) * pageSize;

    let search = searchKeyword ? searchKeyword : keyword;
    let filter = await formatFilters();
    const querystring = location.search;
    const params = new URLSearchParams(querystring);
    const separator = '?';
    let modulesDataResult = await ETApi.searchModules(
      search,
      newPageOffSet,
      pageSize,
      filter
    );
    let pageQuantity = 1;
    if (modulesDataResult.modulesCount > 0) {
      pageQuantity = Math.ceil(modulesDataResult.modulesCount / pageSize);
    }

    if (keyword === ' ') {
      setPageOffSet(newPageOffSet);
    }

    if (newPage) {
      params.set('page', newPage);
      window.history.replaceState(
        {},
        '',
        `#${location.pathname}${separator}${params}`
      );
    } else {
      params.delete('page');
      window.history.replaceState(
        {},
        '',
        `#${location.pathname}${separator}${params}`
      );
    }

    if (selectedPartners.length !== 0) {
      params.set('partner', selectedPartners);
      window.history.replaceState(
        {},
        '',
        `#${location.pathname}${separator}${params}`
      );
    } else {
      params.delete('partner');
      window.history.replaceState(
        {},
        '',
        `#${location.pathname}${separator}${params}`
      );
    }

    if (selectedCategories.length !== 0) {
      params.set('category', selectedCategories);
      window.history.replaceState(
        {},
        '',
        `#${location.pathname}${separator}${params}`
      );
    } else {
      params.delete('category');
      window.history.replaceState(
        {},
        '',
        `#${location.pathname}${separator}${params}`
      );
    }
    if (keyword) {
      params.set('keyword', keyword);
      window.history.replaceState(
        {},
        '',
        `#${location.pathname}${separator}${params}`
      );
    } else {
      params.delete('keyword');
      window.history.replaceState(
        {},
        '',
        `#${location.pathname}${separator}${params}`
      );
    }

    setUrlSearch(params.toString());
    setCurrentPage(1);
    setPageQuantity(pageQuantity);
    setModuleCount(modulesDataResult.modulesCount);
    setDataModule(modulesDataResult.modulesData);
    setPage(newPage);
  };

  let searchModulesForm = async () => {
    await searchModules(null, null, 1);
  };

  let addCategoryFilter = async (category) => {
    let includeCategory = selectedCategories.includes(category);
    if (!includeCategory) {
      setSelectedCategories([category]);
      setDisableCategoryChecks(true);
    } else {
      setSelectedCategories([]);
      setDisableCategoryChecks(false);
    }
    setPage(1);
  };

  let addPartnerFilter = async (partner) => {
    let includePartner = selectedPartners.includes(partner);
    if (!includePartner) {
      setSelectedPartners([partner]);
      setDisablePartnerChecks(true);
    } else {
      setSelectedPartners([]);
      setDisablePartnerChecks(false);
    }
    setPage(1);
  };

  let formatFilters = async () => {
    let filter = '';
    selectedCategories.map((element) => {
      if (filter.length > 0) {
        filter += ` and `;
      }
      filter += `categories.id==${element}`;
    });
    selectedPartners.map((element) => {
      if (filter.length > 0) {
        filter += ` and `;
      }
      filter += `partner.id==${element}`;
    });
    selectedTags.map((element) => {
      if (filter.length > 0) {
        filter += ` and `;
      }
      filter += `tags==${element}`;
    });
    return filter;
  };

  const checkIfIsEmpty = (object) => {
    return object === null || object === undefined || object === '';
  }

  let publicUrl = process.env.PUBLIC_URL + '/';
  return (
    <section
      data-testid='modules-page'
      className='blog-page-area go-top d-flex justify-content-center pd-top-100 pd-bottom-50 '
    >
      <div className='product-category-container'>
        <div className='d-flex justify-content-center pd-bottom-50'>
          <div className='col-12 pd-bottom-30'>
            <form
              data-testid='search-bar-modules-page'
              className='d-flex flex-row pd-top-15  pd-bottom-20'
              onSubmit={searchModulesForm}
            >
              <input
                data-testid='input-modules-page'
                className='form-control'
                placeholder='Search'
                value={keyword}
                onChange={(evt) => setKeyword(evt.target.value)}
                type='text'
              />
              <button
                data-testid='submit-search-modules-page'
                type='submit'
                className='btn btn-search'
              >
                <img
                  src={publicUrl + 'assets/img/icons/icon-search.png'}
                  alt='Search button icon'
                />
              </button>
            </form>
            <div className='isotope-filters item-isotope-btn text-lg-left'>
              {!checkIfIsEmpty(dataModule) ? (
                <p> Modules found ( {moduleCount} )</p>
              ) : (
                <p> Modules found ( 0 )</p>
              )}
            </div>
          </div>
        </div>

        <div className='row' data-testid='left-bar'>
          <div className='col-lg-2 order-lg-first'>
            <div className='sidebar-area'>
              <div className='widget widget-category'>
                <h5 className='widget-title'>Category</h5>
                {categories &&
                  categories.map((category) => {
                    return (
                      <div className='form-check' key={category.id}>
                        <input
                          checked={
                            selectedCategories.includes(category.id)
                              ? true
                              : false
                          }
                          className='form-check-input'
                          type='checkbox'
                          value={category.id}
                          disabled={
                            disableCategoryChecks &&
                            !selectedCategories.includes(category.id)
                          }
                          id={'categoryCheck' + category.id}
                          onClick={() => addCategoryFilter(category.id)}
                          data-testid={category.id}
                        />
                        <label
                          className='form-check-label'
                          htmlFor={'categoryCheck' + category.id}
                          id={'categoryCheck' + category.id}
                        >
                          {category.name}
                        </label>
                      </div>
                    );
                  })}
              </div>
              <div className='widget widget-category'>
                <h5 className='widget-title'>Partner</h5>
                {partners &&
                  partners.map((partner) => {
                    return (
                      <div className='form-check' key={partner.id}>
                        <input
                          checked={
                            selectedPartners.includes(partner.id) ? true : false
                          }
                          className='form-check-input'
                          type='checkbox'
                          value={partner.id}
                          disabled={
                            disablePartnerChecks &&
                            !selectedPartners.includes(partner.id)
                          }
                          id={'partnerCheck' + partner.id}
                          onClick={() => addPartnerFilter(partner.id)}
                          data-testid={partner.id}
                        />
                        <label
                          className='form-check-label'
                          htmlFor={'partnerCheck' + partner.id}
                          id={'partnerCheck' + partner.id}
                        >
                          {partner.name}
                        </label>
                      </div>
                    );
                  })}
              </div>
              <div className='widget widget-product-sorting widget-border'>
                {showTags === true ? (
                  <>
                    <h4 className='title'>Filter by tags</h4>
                    <div
                      data-testid='tags-box'
                      className='row'
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <text className='badge badge-pill badge-primary'>
                        {selectedTags}
                      </text>
                      <MdClose
                        style={{ height: 40, paddingTop: 8 }}
                        onClick={() => [
                          setShowTags(false),
                          setSelectedTags([]),
                        ]}
                        to={`/modules`}
                        size={15}
                        data-testid='close-tags'
                      />
                    </div>
                  </>
                ) : <></>}
              </div>
            </div>
          </div>
          <div className='col order-lg-last'>
            <div
              className='all-item-section all-item-area-1'
              data-testid='cards-box'
            >
              <div className='row'>
                {!checkIfIsEmpty(dataModule) ? (
                  dataModule.length <= 0 ? (
                    <Loading />
                  ) : (
                    dataModule.map((data) => {
                      return (
                        <Card
                          title={data.title}
                          overview={data.overview}
                          id={data.id}
                          javaPackage={data.javaPackage}
                          fromCore={data.fromCore}
                          core={data.core}
                          key={data.id}
                          partnerName={data.partner_data.name}
                          partnerImage={data.partner_data.image}
                          cardImage={data.module_img}
                          partnerId={data.partner_data.id}
                          urlSearch={urlSearch}
                        />
                      );
                    })
                  )
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}
                    data-testid='not-found'
                  >
                    <img
                      src={publicUrl + 'assets/img/not-result.webp'}
                      alt='Not result'
                    />
                    <h2>Sorry</h2>
                    <h4>We couldn't find any modules for your search.</h4>
                  </div>
                )}
              </div>
            </div>
            <div
              className='pagination-wrap text-center mt-2'
              data-testid='button-pagination'
            >
              {checkIfIsEmpty(dataModule) ? (
                <ul className='pagination pagination-2'>
                  {page > 1 ? (
                    <li
                      className='page-item'
                      onClick={(e) => searchModules(null, e, true)}
                    >
                      <span className='page-link' href='#'>
                        <i className='la la-angle-left' />
                      </span>
                    </li>
                  ) : <></>}
                  {[...Array(pageQuantity)].map((x, i) => (
                    <li
                      key={i + 1}
                      className={
                        currentPage === i + 1 ? 'page-item active' : 'page-item'
                      }
                    >
                      <button
                        value={i + 1}
                        style={{
                          backgroundColor: page === i + 1 ? '#202452' : 'white',
                          color: page === i + 1 ? 'white' : '#202452',
                        }}
                        data-testid={'value'}
                        className='page-link'
                        onClick={(e) => searchModules(null, e, i + 1)}
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                  {pageQuantity !== page ? (
                    <li
                      className='page-item'
                      onClick={(e) => searchModules(null, e, false)}
                      data-testid='false-button'
                    >
                      <span className='page-link' href='#'>
                        <i className='la la-angle-right' />
                      </span>
                    </li>
                  ) : <></>}
                </ul>
              ) : (
                <p> </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductCategory;
