import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './sass/style.scss';
import App from './components/App/App'

class Root extends Component {
  render() {
    return (
      <HashRouter basename='/'> 
        <div>
          <App/>
        </div>
      </HashRouter> 
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('marketplace'));