import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import ModuleCode from '../ModuleCode/ModuleCode'
import './Card.scss'

function Card(props) {
  const [showInstallButton, setShowInstallButton] = useState(true);
  const [showCode, setShowCode] = useState(false);
  const [partner, setPartner] = useState('');
  const [partnerimg, setPartnerImg] = useState();
  const [img, setImg] = useState();
  const [partnerId] = useState(props.partnerId);
  const location = useLocation();
  const history = useHistory();

  const data = {
    pathname: '/product-details',
    search: `?module=${props.id}`,
    state: { previousComponent: location.pathname,search: props.urlSearch !== '' ? '?'+ props.urlSearch : props.urlSearch }
  }
  
  useEffect(() => {
    initData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let initData = async () => {
    setPartner(props.partnerName);
    setPartnerImg(props.partnerImage);
    setImg(
      props.cardImage === undefined
        ? 'https://drive.google.com/uc?export=view&id=1MyyynjRF6A8yiZ0um5rfOmAc4aifcJ-d'
        : props.cardImage.imageUrl
    );
  };

  let showInstallCode = () => {
    setShowCode(true);
    setShowInstallButton(false);
  };

  let hideInstallCode = () => {
    setShowCode(false);
    setShowInstallButton(true);
  };

  let filterByPartner = () => {
    if (location.pathname === '/modules') {
      history.replace(`/modules?partner=${partnerId}`);
      history.go();
    }
  };

  return (
    <div
      className='all-isotope-item col-lg-4 col-sm-6 cat-1 cat-3'
      data-testid='module'
    >
      <div className='thumb'>
        <Link
          className='gallery-fancybox'
          to={data}
        >
          <img className='gallery-fancybox mx-auto d-block' src={img} alt=' ' />
        </Link>
        <Link
          className='btn btn-white go-top'
          data-testid='button-read-more'
          to={data}
        >
          Read more
        </Link>
      </div>
      <div className='item-details container emkpModuleInformation'>
        <h4 className='emkpModuleInformation-title'>
          <Link
            data-testid={props.id}
            to={data}
          >
            {props.title}
          </Link>
        </h4>
        <p>{props.overview}</p>
        <div>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            {showInstallButton ? (
              <div
                className='badge-pill-card'
                onClick={showInstallCode}
                data-testid='button-modules-card'
              >
                Install
              </div>
            ) : <></>}
          </div>
          {showCode ? (
            <>
              <div
                data-testid='install-code'
                className='d-flex flex-row justify-content-end align-items-center'
              >
                <div data-testid='close-code' onClick={hideInstallCode}>
                  <MdClose size={15} />
                </div>
              </div>
              <ModuleCode
                javaPackage={props.javaPackage}
              />
            </>
          ) : <></>}
        </div>
        <div className='author-details align-item-center'>
          <img className='emkpModuleInformation-partnerImg' src={partnerimg} alt='Module author' />
          <span className='emkpModuleInformation-partnerName'>
            <Link
              onClick={() => filterByPartner()}
              data-testid='author-name'
              to={`/modules?partner=${partnerId}`}
            >
              {partner}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Card;
