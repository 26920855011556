import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';

function ModuleCode(props) {
  const [copied, setCopied] = useState(false);
  let splitGroup = (pkg) => {
    const javaPackage = pkg.split('.');
    return javaPackage[0].concat('.').concat(javaPackage[1]);
  };
  let splitArtifact = (pkg) => {
    const javaPackage = pkg.split('.');
    return javaPackage.slice(2).join('.');
  };
  let group;
  let artifact;
  if (props.javaPackage) {
    group = splitGroup(props.javaPackage);
    artifact = splitArtifact(props.javaPackage);
  }

  let codeZip = `moduleDeps ('${group}:${artifact}:latest.release@zip'){ transitive = true }`;
  let codeJar = `implementation ('${group}:${artifact}:latest.release')`;
  return (
    <>
      <div className='title-install'>Source Code Format</div>
      <code className='codeCard'>
        <div className='d-flex flex-row justify-content-end'>
          <CopyToClipboard text={codeZip} onCopy={() => setCopied(true)}>
            {copied ? (
              <div className='copied'>Copied!</div>
            ) : (
              <MdContentCopy className='copied' size={15} />
            )}
          </CopyToClipboard>
        </div>
        {codeZip}
      </code> 
      <br></br>
      <div className='title-install'>.JAR Format</div>
      <code className='codeCard'>
        <div className='d-flex flex-row justify-content-end'>
          <CopyToClipboard text={codeJar} onCopy={() => setCopied(true)}>
            {copied ? (
              <div className='copied'>Copied!</div>
            ) : (
              <MdContentCopy className='copied' size={15} />
            )}
          </CopyToClipboard>
        </div>
        {codeJar}
      </code>
    </>
  );
}
export default ModuleCode;
