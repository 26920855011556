import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './ProductSlider.scss';

function ProductSlider(props) {
  const [mediaPreview, setMediaPreview] = useState([]);

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.img]);

  const initData = async () => {
    // eslint-disable-next-line array-callback-return
    if (props.img.length !== 0) {
      const media = [];
      // eslint-disable-next-line array-callback-return
      props.img.map(item => {
        if (item.preview === true && item.card === false && item.video === false) {
          media.push({ imagen: item.imageUrl, original: item.imageUrl, title: item.title || item._identifier })
        }
        if (item.preview === false && item.card === false && item.video === true) {
          media.push({
            video: `${item.imageUrl}?rel=0`,
            embedUrl: `${item.imageUrl}?rel=0`,
            original: `${item.imageUrl}?rel=0`,
            renderItem: _renderVideo.bind(item.imageUrl),
            title: item.title || item._identifier
          });
        }
      });
      sortImages(media);
    }
  };

  const sortImages = (media) => {
    const sortByTitle = (x, y) => {
      if (x.title < y.title) { return -1; }
      if (x.title > y.title) { return 1; }
      return 0;
    }

    if (media.length !== 0) {
      const sortedMediaPreview = media.sort(sortByTitle);
      setMediaPreview(sortedMediaPreview);
    }
  }

  const _renderVideo = (item) => {
    return (
      <div>
        {item ? (
          <div className='video-wrapper'>
            <span className='close-video' />
            <iframe
              className='video-iframe'
              title={item}
              src={item.embedUrl || item.original}
              frameBorder={0}
            />
          </div>
        ) : (
          <span>
            <div className='play-button'></div>
            <img className='image-gallery-image' src={item.original} alt='' />
          </span>
        )}
      </div>
    );
  };

  return (
    <div className={'emkpSlider-container'}>
      {props.img.length !== 0 ? (
        <ImageGallery
          className={'emkpSlider-image'}
          showFullscreenButton={true}
          showPlayButton={false}
          showBullets={false}
          showNav={true}
          infinite={false}
          items={mediaPreview}
        />
      ) : <></>}
    </div>
  )
}

export default ProductSlider;
