import React from 'react';
import Navbar from '../Navbar/Navbar';
import Error from '../Error/Error'
import Footer from '../Footer/Footer';

const ErrorContainer = () => {
  return (
    <div>
      <Navbar />
      <Error />
      <Footer />
    </div>
  );
};

export default ErrorContainer;
