import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { RiExternalLinkFill } from 'react-icons/ri';
import './Navbar.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function NavbarV2() {
  let publicUrl = process.env.PUBLIC_URL + '/';

  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [keyboard, setKeyword] = useState('');

  let query = useQuery();

  useEffect(() => {
    initData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let initData = async () => {
    let keyword = query.get('keyword');
    if (keyword) {
      setKeyword(keyword);
    }
  };

  let refreshHome = () => {
    if (location.pathname === '/') {
      history.go('/');
    }
  };

  let refreshModules = () => {
    if (location.pathname === '/modules') {
      history.replace('/modules?keyword= ');
      history.go('/modules');
    }
  };

  return (
    <div data-testid='navbar'>
      <div className='navbar-area navbar-area-2 go-top'>
        <nav className='navbar navbar-expand-lg'>
          <div className='container nav-container'>
            <div className='responsive-mobile-menu'>
              <button
                className='menu toggle-btn d-block d-lg-none'
                data-target='#dkt_main_menu'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='icon-left' />
                <span className='icon-right' />
              </button>
            </div>
            <div className='logo'>
              <Link to='/'>
                <img
                  src={publicUrl + 'assets/img/logo-etendo-1.png'}
                  alt='Etendo logo'
                />
              </Link>
            </div>
            <div className='collapse navbar-collapse emkpNavbar-buttonList-container' id='dkt_main_menu'>
              <ul className='navbar-nav menu-open emkpNavbar-buttonList'>
                <li className='emkpNavbar-buttonList-element'>
                  <Link data-testid='button-home' to='/' onClick={refreshHome}>
                    <p className='emkpNavbar-buttonList-homeBtn' >Home</p>
                  </Link>
                </li>
                <li className='emkpNavbar-buttonList-element'>
                  <Link
                    data-testid='button-modules'
                    to='/modules'
                    id='etendo-logo'
                    onClick={refreshModules}
                  >
                    <p className='emkpNavbar-buttonList-modulesBtn'>Modules</p>
                  </Link>
                </li>
                <li className='emkpNavbar-buttonList-element'>
                  <a
                    data-testid='button-contact'
                    href='https://etendo.software/get-in-touch/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <p className='emkpNavbar-buttonList-contactBtn'>Contacts</p>
                  </a>
                </li>
                <li className='emkpNavbar-buttonList-element'>
                  <a
                    data-testid='button-etendo'
                    className='badge-pill-card'
                    href='https://etendo.software/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Etendo{' '}
                    <RiExternalLinkFill className='icon-padding' size={14} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default NavbarV2;
