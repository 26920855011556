import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '../Card/Card';
import ETApi from '../../ETApi/ETApi';
import Loading from '../Loading/Loading';

function Featured() {
  const [dataModule, setDataModule] = useState([]);

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let initData = async () => {
    await ETApi.initApi();
    let search = ''
    let pageOffset = null
    let pageSize = null
    let filter = await formatFilters();
    let modulesDataResult = await ETApi.searchModules(
      search,
      pageOffset,
      pageSize,
      filter
    );

    let modulesData = modulesDataResult.modulesData;
    setDataModule(modulesData);
  };


  let formatFilters = async () => {
    let filter = '';
    if (filter.length > 0) {
      filter += ` and `;
    }
    filter += `quality.id==02E346C9D3F9473EB1C374D541B44E15`;
    return filter;
  };

  return (
    <section data-testid='featured' className='featured-area bg-sky-blue pd-top-90 pd-bottom-100'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-5'>
            <div className='section-title text-center'>
              <h2>Featured Modules</h2>
            </div>
          </div>
        </div>
        <div className='all-item-section all-item-area-1'>
          <div className='row'>
            {dataModule !== null ? (
              dataModule.length === 0 ? (
                <Loading />
              ) : (
                dataModule.map((data) => {
                  return (
                    <Card
                      title={data.title}
                      overview={data.overview}
                      id={data.id}
                      javaPackage={data.javaPackage}
                      fromCore={data.fromCore}
                      core={data.core}
                      key={data.id}
                      partnerName={data.partner_data.name}
                      partnerImage={data.partner_data.image}
                      cardImage={data.module_img}
                      partnerId={data.partner_data.id}
                    />
                  );
                })
              )
            ) : (
              <div> </div>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 text-center go-top'>
            <Link data-testid='button-more-modules' className='btn btn-base' to='/modules'>
              View all modules
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Featured;
