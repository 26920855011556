import React from 'react';
import Navbar from '../../Navbar/Navbar';
import ProductCategory from '../ProductCategory/ProductCategory';
import Footer from '../../Footer/Footer';

const Category = (props) => {
  return (
    <div>
      <Navbar />
      <ProductCategory params={props.match.params} />
      <Footer />
    </div>
  );
};

export default Category;
