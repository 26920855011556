import React from 'react';
import { Link } from 'react-router-dom';

function Error() {
  let publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <section  className='error-page-area pd-top-100 pd-bottom-100' data-testid='section-componente-error'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8 col-md-10'>
            <div className='error-inner text-center'>
              <img
                src={publicUrl + 'assets/img/error/404.png'}
                alt='Error 404'
              />
              <h2>Oops!</h2>
              <p>There's nothing here!</p>
              <Link className='btn btn-base' to='/'>
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Error;
