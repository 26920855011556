import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import ProductDetails from '../Product/ProductDetails/ProductDetailsContainer'
import Category from '../Product/ProductCategory/ProductCategoryContainer'
import Error from '../Error/ErrorContainer'

function App() {
    return (
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/modules' component={Category} />
        <Route exact path='/product-details' component={ProductDetails} />
        <Route path='*' component={Error} />
      </Switch>
    )
}

export default App;